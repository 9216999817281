html {
    font-family: $main-font;
}

p {
    font-size: 20px;
    line-height: 1.2;
}

a {
    font-size: 14px;
    text-decoration: underline;
}

label {
    font-size: 22px;
    display: block;
}

input[type='text'],
input[type='email'] {
    background-color: transparent;
    border: solid 1px $black;
    padding: 20px;
    display: block;
    font-size: 22px;
}

input[type="checkbox"] {
    appearance: none;
    display: block;
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: solid 1px $black;
    border-radius: 0;

    &:checked {
        background-color: $black;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url('./assets/img/checkmark.svg');
            background-size: contain;
        }
    }
}

input:focus {
    outline: solid 1px black;
}

.button {
    font-family: "Josefin Sans";
    font-weight: 400;
    font-size: 26px;
    font-weight: bold;
    padding: 18px 56px;
    color: $white;
    background-color: #547666;
    border-radius: 10px;
}

.container {
    max-width: 1098px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 118px;

    @include m1440{
        padding-right: 0;
    }
}

br {
    display: none;

    @include m768 {
        display: block;
    }
}