.intro {
    margin: 25px 0 40px;
    font-weight: 700;
    
    p{
        font-size: 24px;
        letter-spacing: 0.4px;
        line-height: 32px;
    }

    @include m1130 {
        padding: 0 20px;
    }

    p:not(:last-of-type) {
        margin-bottom: 25px;
    }
}

.chapeau {
    margin: 25px 0;
    font-weight: 700;
    
    p{
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.4px;
    }

    @include m1130 {
        padding: 0 20px;
    }

    p:not(:last-of-type) {
        margin-bottom: 25px;
    }
}

.auteur{
    display: flex;
    align-items: center;
    justify-content: center;

    & > *{
        margin: 0 10px;
        text-align: center;
    }
    
    &_nom{
        font-weight: bold;
        font-style: italic;
    }

    @include m768 {
        flex-direction: column;

        &_infos{
            margin-top: 10px;
        }
    }
}

.figures {
    background-color: $accent;
    // color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 55px 60px 55px 40px;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 45%;

        &:nth-of-type(1) {
            max-width: 380px;
        }

        &:nth-of-type(2) {
            max-width: 400px;
        }

        span {
            font-family: $title-font;
            font-weight: 400;
            font-size: 78px;
            font-weight: bold;
            margin-right: 25px;
        }

        p {
            font-weight: bold;
        }
    }

    @include m768 {
        display: block;
        padding: 55px 25px 40px 25px;

        li {

            &:nth-of-type(1),
            &:nth-of-type(2) {
                width: 100%;
                margin: auto;
                max-width: 380px;
            }

            &:nth-of-type(1) {
                margin-bottom: 65px;
            }

            span {
                margin-right: 30px;
                line-height: 0.8;
            }
        }
    }

    @include m400 {
        li {
            align-items: flex-start;
        }
    }
}

.invitation {
    background-color: #BCD5CA;
    border-radius: 20px;
    box-shadow: 20px 20px 0px 0px #A7C9BB;

    padding: 65px 0 57px;
    margin: 60px 0 102px;
    text-align: center;
    
    &__titre{
        font-family: "Josefin Sans";
        text-transform: uppercase;
        color: #547666;
        font-weight: bold;
        font-size: 40px;
        margin-bottom: 17px;
    }

    &__texte {
        color: #000;
        font-weight: 400;
        font-size: 26px;
        margin-bottom: 7px;
        letter-spacing: -0.6px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    @include m1130 {
        width: calc(100% - 20px);
    }

    @include m768 {
        margin-top: 20px;
        margin-bottom: 40px;
        padding: 65px 20px;

        p {
            &:nth-of-type(1) {
                font-size: 35px;
                margin-bottom: 18px;
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                font-size: 20px;
            }
        }
    }
}

.form {
    @include m1130 {
        padding: 0 20px;
    }

    @include m400 {
        &__submit {
            width: 100%;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 30px;

    }

    &__success {
        display: none;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 150px;
    }

    &__row {
        display: flex;

        .form__field:first-of-type {
            margin-right: 25px;
        }

        @include m768 {
            display: block;

            .form__field:first-of-type {
                margin-right: 0;
            }
        }
    }

    &__field,
    &__optin {
        margin-bottom: 30px;
        width: 100%;
    }

    &__field {
        label {
            margin-bottom: 15px;
        }

        input {
            width: 100%;
        }
    }

    &__optin {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include m768 {
            align-items: flex-start;
        }

        input {
            margin-right: 20px;
            flex-shrink: 0;
        }

        label {
            max-width: calc(100% - 50px);
        }

        .form__error {
            width: 100%;
        }
    }

    &__captcha {
        margin: 30px 0;
        width: 280px;

        @include m400 {
            width: 100%;

            .frc-captcha {
                min-width: 100%;
            }
        }
    }

    &__error {
        color: $danger;
        font-size: 12px;
        font-weight: bold;
        display: none;
        margin-top: 10px;

        &.--global {
            margin-bottom: 10px;
        }
    }

    &.--haserrors {
        .form__error {
            display: block;
        }
    }

    &.--success {
        .form__success {
            display: block;
        }

        form {
            display: none;
        }
    }
}