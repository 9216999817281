.header {
    padding-top: 45px;
    padding-bottom: 45px;
    background-color: $white;

    &__title {
        font-family: "Din-pro";
        font-size: 93px;
        font-weight: 700;
        color: $white;
        margin-bottom:40px;

        @include m768 {
            font-size: 36px;
            margin: 30px auto 20px;
        }

        span {
            display: block;
        }
    }

    &__logo {
        display: flex;
        margin-bottom: 15px;

        @include m1130 {
            padding: 0 20px;
        }

        @include m768 {
            justify-content: center;
            margin-bottom: 24px;
        }

        img {
            width: 190px;
        }
    }

    &__banner {
        width: 100%;
        background-color: #2A337D;
        background-image: url('./assets/img/bgd-banner.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        padding: 0 30px;
        @include m768 {
            padding: 0 20px;
            // background: linear-gradient($accent 0 calc(100% - 103px), $white calc(100% - 104px));
        }
    }

    &__box {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 873px;
        margin: 0 auto;
        gap:30px;

        @include m768 {
            flex-direction: column;
        }
        &-content {
            position: relative;
            color:$white;
            flex-shrink: 0;
            z-index: 1;
            p {
                font-family: "Dinot-CondMedium";
                font-size: 39px;
                font-weight: 500;
                line-height: 43px;
                text-transform: uppercase;
                br {
                    display: block;
                }
            }
        }
        &-visuel {
            position: relative;
            max-width: 597px;
            z-index: 0;
            padding: 13px 0 17px;
            @include m768 {
                width: 100%;
            }
            img {
                @include m768 {
                    text-align: center;
                }
            }
        }
    }

    &__date {
        margin-left: 4px;
        max-width: 260px;

        @include m768 {
            margin-left: 0;
            margin-top: 30px;
            margin-bottom: 18px;
            max-width: 140px;
        }
    }

    &__face {
        width: 100%;
        max-width: 100%;
    }
}