.header {
    padding-top: 33px;
    padding-bottom: 55px;
    background-color: $white;

    &__title {
        font-family: "Josefin Sans";
        font-size: 93px;
        font-weight: 700;
        color: #577D6C;
        margin-bottom:40px;
        text-transform: uppercase;
        display: flex;
        position: absolute;
        right: -15px;
        top: 82px;

        strong{
            font-size: 110px;
            font-weight: 400;
            line-height: 94px;
            margin-right: 12px;
            letter-spacing: -5px;
        }

        .moisannee{
            line-height: 41px;
        }

        .mois{
            font-size: 39px;
            font-weight: 700;
        }

        .annee{
            font-size: 50px;
            font-weight: 400;
        }

        @include m768 {
            font-size: 36px;
            margin: 30px auto 20px;
        }

        span {
            display: block;
        }

        @include m768{
            top: 12px;
            transform: scale(0.7);
        }
    }

    &__block{
        position: absolute;
        bottom: 56px;
        text-align: center;
        left: -68px;
        width: 100%;

        @include m1440{
            left: 0;
        }
        @include m768{
            bottom: 26px;
        }
    }

    &__logo {
        display: flex;
        margin-bottom: 29px;

        @include m1130 {
            padding: 0 20px;
        }

        @include m768 {
            justify-content: center;
            margin-bottom: 24px;
        }

        img {
            width: 190px;
        }
    }

    &__banner {
        width: 100%;
        height: 662px;
        background-color: #A7C9BB;
        background-image: url('./assets/img/bgd-banner.jpg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;
        display: flex;
        align-items: center;
        padding: 0 30px;
        position: relative;
        @include m1440 {
            background-position: 45% center;
        }
        @include m1130 {
            background-size: auto 85%;
        }
        @include m768 {
            background-position: 45% 80%;
            background-size: auto 90%;
            height: 502px;
        }
        @include m600 {
            background-position: 45% 80%;
            background-size: auto 80%;
            height: 400px;
        }
        @include m400 {
            background-position: 45% 50%;
            background-size: auto 55%;
        }
    }

    &__box {
        width: 100%;
        height: 100%;
        max-width: 873px;
        margin: 0 auto;

        @include m768 {
            flex-direction: column;
        }
        &-content {
            position: relative;
            color:$white;
            flex-shrink: 0;
            z-index: 1;
            height: 100%;
            p {
                font-family: "Josefin Sans";
                font-size: 30px;
                font-weight: 400;
                line-height: 47px;
                color: #577D6C;
                text-transform: uppercase;
                br {
                    display: block;
                }

                @include m1130{
                    font-size: 20px;
                    line-height: 30px;
                }
                @include m768{
                    font-size: 18px;
                    line-height: 27px;
                }
                @include m768{
                    font-size: 16px;
                    line-height: 24px;
                }
                @include m600{
                    letter-spacing: -0.8px;
                }
            }

            
        }
        &-visuel {
            position: relative;
            max-width: 597px;
            z-index: 0;
            padding: 13px 0 17px;
            @include m768 {
                width: 100%;
            }
            img {
                @include m768 {
                    text-align: center;
                }
            }
        }
    }

    &__date {
        margin-left: 4px;
        max-width: 260px;

        @include m768 {
            margin-left: 0;
            margin-top: 30px;
            margin-bottom: 18px;
            max-width: 140px;
        }
    }

    &__face {
        width: 100%;
        max-width: 100%;
    }
}