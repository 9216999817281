.footer {
    margin-top: 75px;
    background-color: $black;
    padding: 0 40px 30px 40px;

    @include m1130 {
        padding: 0 20px 30px 20px;
    }

    @include m768 {
        margin-top: 40px;
    }

    &__legal {
        background-color: $white;
        padding: 30px 10px;

        p {
            font-size: 14px;
            color: $black;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }

    &__nav {
        color: $white;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        span {
            font-size: 14px;
        }

        a:after {
            content: '-';
            display: inline-block;
            margin: 0 5px;
        }

        @include m400 {
            flex-direction: column;

            a {
                margin-bottom: 10px;

                &:after {
                    display: none;
                }
            }
        }
    }
}