@font-face {
    font-family: 'Baikal';
    font-weight: 400;
    src: url('./assets/fonts/baikal/Baikal-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Baikal-expanded';
    font-weight: 400;
    src: url('./assets/fonts/baikal/Baikal-ExpandedRegular.otf') format('opentype');
}

@font-face {
    font-family: 'Baikal-expanded';
    font-weight: 500;
    src: url('./assets/fonts/baikal/Baikal-ExpandedMedium.otf') format('opentype');
}
@font-face {
    font-family: 'Dinot-cdn';
    font-weight: 400;
    src: url('./assets/fonts/din-ot/dinot-cnd.otf') format('opentype');
}
@font-face {
    font-family: 'Dinot-CondMedium';
    font-weight: 400;
    src: url('./assets/fonts/din-ot/Dinot-CondMedium.otf') format('opentype');
}
@font-face {
    font-family: 'Din-pro';
    font-weight: 400;
    src: url('./assets/fonts/din-ot/DINPro-CondBold.otf') format('opentype');
}