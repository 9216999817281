@mixin m1440 {
    @media screen and (max-width: 1440px){
        @content;
    }
}
@mixin m1130 {
    @media screen and (max-width: 1130px){
        @content;
    }
}
@mixin m767 {
    @media screen and (min-width: 767px){
        @content;
    }
}
@mixin m768 {
    @media screen and (max-width: 768px){
        @content;
    }
}
@mixin m600 {
    @media screen and (max-width: 600px){
        @content;
    }
}
@mixin m400 {
    @media screen and (max-width: 400px){
        @content;
    }
}