@font-face {
    font-family: 'Baikal';
    font-weight: 400;
    src: url('./assets/fonts/baikal/Baikal-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Baikal-expanded';
    font-weight: 400;
    src: url('./assets/fonts/baikal/Baikal-ExpandedRegular.otf') format('opentype');
}

@font-face {
    font-family: 'Baikal-expanded';
    font-weight: 500;
    src: url('./assets/fonts/baikal/Baikal-ExpandedMedium.otf') format('opentype');
}
@font-face {
    font-family: 'Dinot-cdn';
    font-weight: 400;
    src: url('./assets/fonts/din-ot/dinot-cnd.otf') format('opentype');
}
@font-face {
    font-family: 'Dinot-CondMedium';
    font-weight: 400;
    src: url('./assets/fonts/din-ot/Dinot-CondMedium.otf') format('opentype');
}
@font-face {
    font-family: 'Din-pro';
    font-weight: 400;
    src: url('./assets/fonts/din-ot/DINPro-CondBold.otf') format('opentype');
}
/* josefin-sans-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/josefin/josefin-sans-v32-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* josefin-sans-700 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./assets/fonts/josefin/josefin-sans-v32-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }